"use client";

import { CartIcon } from "@/components/icons/CartIcon";
import Button from "@/components/ui/Button";
import navBarStyles from "@/components/ui/Navbar/index.module.css";
import { useCart } from "@/lib/centra/hooks/useSelection";
import { useTranslations } from "next-intl";

const CartToggle = () => {
  const t = useTranslations();
  const { cart } = useCart();
  const quantity = cart.totals?.totalQuantity;

  return (
    <Button mode="unset" modal={"cart"} className={navBarStyles.navbarLinks}>
      <p className={navBarStyles.linksDesktop}>
        {t("megaMenu.cart")}
        {!!quantity && <>({quantity})</>}
      </p>
      <span className={navBarStyles.linksMobile}>
        <CartIcon />
      </span>
    </Button>
  );
};

export default CartToggle;
