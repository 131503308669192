"use client";

import Search from "@/components/icons/Search";
import { useSearchModal } from "@/features/search/SearchModal";
import { useTranslations } from "next-intl";
import Button from "../Button";
import styles from "./index.module.css";

export const SearchItem = () => {
  const { setOpen } = useSearchModal();
  const t = useTranslations();
  return (
    <Button
      onClick={() => setOpen(true)}
      mode="unset"
      className={styles.navbarLinks}
    >
      <p className={styles.linksDesktop}>{t("megaMenu.search")}</p>
      <span className={styles.linksMobile}>
        <Search />
      </span>
    </Button>
  );
};
