"use client";

import { AccountIcon } from "@/components/icons/Account";
import { useAuth } from "@/lib/centra/hooks/useAuth";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import Button from "../Button";
import styles from "./index.module.css";

export const AccountItem = () => {
  const { isLoggedIn } = useAuth();
  const t = useTranslations();
  const pathname = usePathname();

  if (isLoggedIn)
    return (
      <Button
        mode="unset"
        className={clsx(styles.item, styles.navbarLinks)}
        href="/account"
      >
        <p className={styles.linksDesktop}>{t("megaMenu.myPage")}</p>
        <span className={styles.linksMobile}>
          <AccountIcon />
        </span>
      </Button>
    );

  return (
    <Button
      mode="unset"
      className={clsx(styles.item, styles.navbarLinks)}
      modal={"login"}
    >
      <p className={styles.linksDesktop}>{t("megaMenu.myPage")}</p>
      <span className={styles.linksMobile}>
        <AccountIcon />
      </span>
    </Button>
  );
};
