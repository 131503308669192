"use client";

import { FavouriteIcon } from "@/components/icons/FavouriteIcon";
import { useTranslations } from "next-intl";
import Button from "../Button";
import styles from "./index.module.css";

export const FavouriteItem = () => {
  const t = useTranslations();
  return (
    <Button href="/favourites" mode="unset" className={styles.navbarLinks}>
      <p className={styles.linksDesktop}>{t("megaMenu.favourites")}</p>
      <span className={styles.linksMobile}>
        <FavouriteIcon />
      </span>
    </Button>
  );
};
