/**
 * Static blok which fetches data for the search modal
 */

import { serverFetchPCByIds } from "@/lib/centra/serverFunctionsCentra";
import { buildGetFormattedStory } from "@/lib/storyblok/storyblokSetup";
import { ProductListingStoryblok } from "@/lib/types/storyblok-blok-types";
import { StoryblokStory } from "storyblok-generate-ts";
import { SearchModal } from "./SearchModal";

const formatBlok = (story: StoryblokStory<ProductListingStoryblok>) => {
  const blok = story?.content;

  return blok;
};
type ResolvedSearchBlok = Awaited<ReturnType<typeof formatBlok>>;

const getFeaturedProductsBlok = buildGetFormattedStory<
  ProductListingStoryblok,
  ResolvedSearchBlok
>(formatBlok);

const getFeaturedProducts = async (products?: string[]) => {
  if (!products || !products.length) return [];
  const cards = await serverFetchPCByIds(products.map(String));

  return cards.slice(0, 4);
};

export const SearchModalLoader = async ({ locale }: { locale: string }) => {
  const blok = await getFeaturedProductsBlok({
    slugOrId: locale + "/statics/featured-products"
  });

  const featuredProducts = await getFeaturedProducts(
    blok?.products as string[]
  );
  return <SearchModal title={blok.title} featuredProducts={featuredProducts} />;
};
