"use client";

import { useEffect, useRef, useState } from "react";

const useHideOnScroll = (home?: boolean) => {
  const [isHidden, setIsHidden] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const scrollPosition = useRef(0);
  const threshold = home ? 300 : 10;
  useEffect(() => {
    let ticking = false;
    scrollPosition.current = window.scrollY;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const newScrollPosition = window.scrollY;
          if (
            newScrollPosition > scrollPosition.current &&
            newScrollPosition > threshold &&
            !isHidden
          ) {
            setIsHidden(true);
          } else {
            setIsTop(newScrollPosition < threshold);
            setIsHidden(
              newScrollPosition > scrollPosition.current &&
                newScrollPosition > threshold
            );
          }

          scrollPosition.current = newScrollPosition;
          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {
    isHidden,
    isTop
  };
};

export default useHideOnScroll;
